@import "styles/typography.scss";
.pageHeadingWrapper {
    width: calc(100% - 64px);
    margin: 0 auto;
    max-width: 1600px;
}
.pageHeadingContainer {
    margin-top: 64px;
    min-height: calc(45vh - 64px);
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto min-content;
    // Below 650px decrease the height
    @media (max-width: 650px){
        min-height: calc(20vh);
    }
}

.header {
    color: white;
    display: flex;
    align-items: center;
    font-size: $headingLarge;
    font-family: NHGDP, sans-serif;
    @media (max-width: 650px){
        font-size: $headingSmall;
    }
    margin: 0;
}
.headerGray {
    color: white;
    opacity: 50%;
}