@import "styles/typography.scss";
.homeHeadingWrapper {
    width: calc(100% - 64px);
    margin: 0 auto;
    max-width: 1600px;
}
.homeHeadingContainer {
    min-height: calc(70vh - 64px);
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto min-content;
    //padding: 0 32px;
    // Below 650px increase the height
    @media (max-width: 650px){
        min-height: calc(70vh);
    }
}

.header {
    color: white;
    display: flex;
    align-items: center;
    font-size: $headingLarge;
    font-family: NHGDP, sans-serif;
    @media (max-width: 650px){
        font-size: $headingSmall;
    }
}
.headerGray {
    color: white;
    opacity: 50%;
}