@font-face {
    font-family: 'NHGDP';
    src: url('fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'),
        url('fonts/NHaasGroteskDSPro-65Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NHGR';
    src: url('fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'),
        url('fonts/NHaasGroteskDSPro-65Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasGroteskTextPro';
    src: url('fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
        url('fonts/NHaasGroteskTXPro-55Rg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
body,html {
  margin: 0;
  padding: 0;
  font-family: "Fragment Mono", monospace;
}
