.testimonialsWrapper {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-direction: row;
    padding-top: 32px;
    padding-bottom: 0; // footer message is already padded
    // padding-bottom: 32px;
    // below 650 reduce padding and make the flex direction column
    @media (max-width: 650px) {
        padding-top: 16px;
        // padding-bottom: 16px;
        flex-direction: column;
        gap: 16px;
    }
}
.testimonialsGrid {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-items: center;
    @media (max-width: 900px) {
        gap: 12px;
    }
    @media (max-width: 650px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}


.label {
    font-size: 14px;
}

.logo {
    height: 20px;
}

.wideLogo {
    height: 16px;
}

div svg {
    display: inline-block;
    width: auto; /* Maintain aspect ratio */
    height: 100%;
    fill: black;
    vertical-align: middle;
}
