.Label {
  width: fit-content;
  font-size: 16px;
  font-family: "Fragment Mono", monospace;
  color: #9747FF;
  //border: 1px dashed #9747FF;
  border-radius: 100px;
  // below 650px reduce font size
  @media  (max-width: 650px) {
    font-size: 14px;
    padding: 8px 10px;
  }
}
// Add a 8 pixel dot before the label
.Label::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #9747FF;
  border-radius: 50%;
  margin-right: 12px;
}

.white {
  //border: 1px dashed white;
  color: white;
  //padding: 14px;
}

.black {
  //border: 1px dashed black;
  color: black;
  //padding: 14px;
}

// If the label is black or white, remove the dot
.white.Label::before, .black.Label::before {
  //display: none;
  background-color: #ffffff;
  width: 0;
  margin: 0;
}