.backgroundWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  //background-color: rgb(0,67,127);
  background-color: transparent;
  background-image: url("./images/BG_Blue_1.png");
  // loop the background image
}
.backgroundVideoCss {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  // center the background
  background-position: center center;
  background-size: cover;
}
.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background-image: url("./images/BG_Blue_1.png");
}
.backgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  // make the video responsive
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}