.buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 64px;
}

a.linkButton {
    border-radius: 500px;
    padding: 8px 16px;
    color: white;
    text-decoration: none;
    border: 1px dashed transparent;
    background-color: black;

    &:hover {
        color: black;
        border: 1px dashed black;
        text-decoration: none;
        background-color: white;
    }
  }