@import "styles/typography.scss";

.keyFiguresWrapper {
  display: flex;
  justify-content: center;
  width: calc(100% - 64px);
  color: white;
  min-height: calc(80vh - 240px);
  margin: 32px;
  margin-top: 128px;
  // below 650px remove the margin
  @media (max-width: 650px){
    margin-left: 32px;
    margin-right: 32px;
    width: calc(100% - 64px);
  }
}
.keyFiguresContainer {
  max-width: calc(1340px - 240px);
  margin-left: 120px;
  margin-right: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 96px;
  width: 100%;
  // below 650px remove the margin
  @media (max-width: 1000px){
    margin-left: 0;
    margin-right: 0;
    max-width: calc(1600px - 0px);
    gap: 32px;
  }
}
.keyFiguresHeader {
  font-weight: 500;
  color: white;
  @include responsiveMediumHeading();
  font-family: NHGDP, sans-serif;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.keyFiguresGrid {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  // grid with 2 columns
  grid-template-columns: 1fr 1fr;
  // repeat for each row
  grid-auto-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 64px;
  // below 900px change to 1 column
  @media (max-width: 900px){
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }
}

.keyFigureWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;

  &.gridSpan2 {
    grid-column: span 2;
  }
}

.keyFigureValue {
  font-family: NHGDP, sans-serif;
  @include responsiveExtraLargeHeading();

  &.responsiveValueSize {
    font-size: 100px;

    @media (max-width: 1200px){
      font-size: 82px;
    }

    @media (max-width: 900px){
      font-size: 70px;
    }

    @media (max-width: 700px){
      font-size: 68px;
    }

    @media (max-width: 650px){
      font-size: $headingExtraLargeMobile;
    }
  }
}

.keyFigureLabel {
  font-size: 14px;
}