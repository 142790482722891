.sectionWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.sectionContainer {
  height: 100%;
  margin-left: 32px;
  margin-right: 32px;
  //gap: 35px;
  width: 100%;
  max-width: calc(1600px - 124px);
  // below 650px remove the margin
  @media (max-width: 650px){
    margin-left: 0;
    margin-right: 0;
    max-width: calc(1600px - 0px);
  }

  .sectionContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
}
// modifications to the sectionContainer class
.transparentSectionContainer {
  background-color: transparent;
  color: white;
  max-width: 1600px;
  margin-top: 128px;
}
.boxSectionContainer {
  background-color: white;
  border-radius: 32px;
  margin-top: 64px;

  .sectionContent {
    padding: 120px 64px;
    // below 650px remove the padding
    @media (max-width: 650px){
      padding: 64px 32px;
    }
  }
}

.sectionContainer.tightVerticalPadding .sectionContent {
  padding-top: 64px;
  padding-bottom: 64px;

  @media (max-width: 650px){
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.headerBanner {
  position: relative;
  width: 100%;
  height: 240px;
  object-fit: cover;
  background-size: cover;
  border-radius: 32px 32px 0 0;

  &.largeHeaderBanner {
    height: 420px;

    @media (max-width: 1200px) {
      height: 360px;
    }

    @media (max-width: 900px) {
      height: 220px;
    }
  }

  @media (max-width: 900px) {
    height: 140px;
  }

  &.verticalAlignBackground {
    background-position: top;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 32px 32px 0 0;
    pointer-events: none;
    display: none;

    &.visible {
      display: inline;
    }
  }

  .bannerFadeOverlay {
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(to bottom, transparent, white);
    pointer-events: none;
  }
}