.topNavigationWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}
.topNavigationContainer {
  max-width: 1600px;
  width: 100%;
  padding: 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navLogo {
  display: flex;
  align-items: center;
  svg {
    height: 36px
  }
}
.linkWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  // below 900px width change to mobile menu
  @media only screen and (max-width: 900px) {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
    // remove interactivity
    pointer-events: none;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0s;
    &.open {
      // add interactivity back
      pointer-events: initial;
      height: 100%;
      opacity: 1;
    }
    &.closes {
      transition-delay: 0.3s;
      height: 0;
      animation: slideOut 0.3s forwards;
      opacity: 0;
    }
  }
}
.linkItems {
  font-family: "Fragment Mono", monospace;
  display: flex;
  gap: 4px;
  color: black;
  a, a:visited, a:link {
    border-radius: 500px;
    padding: 8px 16px;
    color: black;
    text-decoration: none;
    border: 1px dashed transparent;
    background-color: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(4px);
    width: fit-content;
    @media only screen and (max-width: 900px) {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  a:active, a:hover {
    color: black;
    border: 1px dashed #fff;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 1);
    width: fit-content;
  }
  @media only screen and (max-width: 900px) {
    position: absolute;
    top:0;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 32px;
    margin-top: 31px;
    width: fit-content;
  }
}

.linkWrapper .linkItem {
  @media only screen and (max-width: 900px) {
    transform: translateX(calc(100% + 32px));
  }
}
.linkWrapper.closing .linkItem {
  @media only screen and (max-width: 900px) {
    transform: translateX(0);
  }
}

.mobileMenuToggle {
  display: none;
  @media only screen and (max-width: 900px) {
    color: black;
    padding: 8px 16px;
    width: fit-content;
    display: flex;
    //padding: 8px;
    //margin: -8px;
    background-color: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(4px);
    border-radius: 500px;
  }

}

@keyframes slideIn {
  0% {
    transform: translateX(calc(100% + 32px));
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(100% + 32px));
  }
}
/* Adding delay to each link item */
$link-item-count: 4;
$base-delay: 0.1s;

@for $i from 1 through $link-item-count {
  .linkWrapper.open .linkItem:nth-child(#{$i}) {
    @media only screen and (max-width: 900px) {
      animation: slideIn 0.3s forwards;
      animation-delay: $base-delay * $i;
    }
  }
  .linkWrapper.closing .linkItem:nth-child(#{$i}) {
    @media only screen and (max-width: 900px) {
      animation: slideOut 0.3s forwards;
      animation-delay: $base-delay * $i;
    }
  }
}
