@import "styles/typography.scss";

.checkoutDemoVideoSectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 16px;
    width: 100%;

    @media (max-width: 650px){
      flex-direction: column;
    }
}

.checkoutDemoVideoWrapper {
  position: relative;
}

.checkoutDemoVideoContainer {
  overflow: hidden;
  width: auto;
  display: flex;
  justify-content: flex-start;
  // hide the video on safari iOS
  @supports (-webkit-overflow-scrolling: touch) {
      display: none;
  }
  }
  
.checkoutDemoVideo {
  pointer-events: none;
  margin: 8px 16px;
  transform: scale(1);
  width: 300px;
  border-radius: 16px;
  box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 900px){
    width: 250px;
  }

  @media (max-width: 650px){
    width: 250px;
  }
}

.textSideWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;

  font-family: NHGDP, sans-serif;
  @include responsiveMediumHeading();

  color:white;
  
  .opaque {
    opacity: 50%;
  }
}
