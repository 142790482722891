@import "styles/typography.scss";

.featuresSectionHeader {
  @include responsiveLargeHeading();
  padding-bottom: 120px;

  @media (max-width: 900px){
    padding-bottom: 60px;
  }

  width: 100%;
}

.featuresSectionContent {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 32px;
  height: 640px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 960px;
  }

  @media (max-width: 650px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, 1fr);
    height: auto;
  }
}

/* grid item */
.featureElementContainer {
  position: relative;
  justify-self: stretch;
  align-self: stretch;

  /* 1 */
  &.gridItem1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;

    .featureElementContent {
      &.expanded {
        width: 200%;

        .content {
          flex-direction: row;

          .expandContent {
            padding-top: 32px;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      grid-column: 1 / span 1;
      grid-row: 2 / span 2;

      .featureElementContent {
        &.expanded {
          width: 180%;
        }
      }
    }

    @media (max-width: 650px) {
      grid-column: 1;
      grid-row: 1;

      .featureElementContent.expanded {
        height: 480px;
      }
    }

    @media (max-width: 450px) {
      .featureElementContent.expanded {
        height: 600px;
      }
    }
  }

  /* 2 */
  &.gridItem2 {
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;

    .featureElementContent {
      &.expanded {
        height: 140%;
      }
    }

    @media (max-width: 1200px) {
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
    }

    @media (max-width: 650px) {
      grid-column: 1;
      grid-row: 2;

      .featureElementContent.expanded {
        height: 440px;
      }
    }

    @media (max-width: 450px) {
      .featureElementContent.expanded {
        height: 540px;
      }
    }
  }

  /* 3 */
  &.gridItem3 {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;

    .featureElementContent {
      bottom: 0;
      top: auto;

      &.expanded {
        width: 180%;
        height: 160%;
  
        .content {
          flex-direction: column;
        }
      }
    }

    @media (max-width: 1200px) {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;

      .featureElementContent {
        top: 0;
        bottom: auto;
        right: 0;
        left: auto;
      }
    }

    @media (max-width: 650px) {
      grid-column: 1;
      grid-row: 3;

      .featureElementContent.expanded {
        height: 440px;
      }
    }

    @media (max-width: 450px) {
      .featureElementContent.expanded {
        height: 540px;
      }
    }
  }

  /* 4 */
  &.gridItem4 {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;

    .featureElementContent {
      bottom: 0;
      top: auto;
      right: 0;
      left: auto;

      &.expanded {
        width: 180%;
        height: 160%;
  
        .content {
          flex-direction: column;
        }
      }
    }    

    @media (max-width: 1200px) {
      grid-column: 2 / span 1;
      grid-row: 3 / span 1;
    }

    @media (max-width: 650px) {
      grid-column: 1;
      grid-row: 4;

      .featureElementContent.expanded {
        height: 560px;
      }
    }

    @media (max-width: 450px) {
      .featureElementContent.expanded {
        height: 740px;
      }
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    min-height: 240px;

    &.gridItem1, &.gridItem2, &.gridItem3, &.gridItem4 {
      .featureElementContent {
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;

        &.expanded {
          width: 100%;

          .content {
            flex-direction: column;

            .expandContent {
              padding-top: 0;
            }
          }
        }
      }
    }

    .expandContent {
      width: 100%;
    }
  }
}

.featureElementContent {
  position: absolute;
  top: 0;
  left: 0;
  color:black;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 32px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  transition: width 0.3s, height 0.3s, opacity 0.2s;

  .content {
    flex-direction: column;
  }

  &.expanded {
    z-index: 2;
    overflow: visible;
  }
}

.faded {
  opacity: 0.5;
}

.expanded .content {
  gap: 32px;
}

.content {
  display: flex;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.titleContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.expandContentWrapper {
  position: relative;
  min-width: 55%;
}

.expandContent {
  opacity: 0;
  transition: opacity 0s 0s;
  position: absolute;
  max-width: 100%;
  z-index: -100;
  width: 0;
  height: 0;
}

.expanded .expandContent {
  opacity: 1;
  z-index: initial;
  transition: opacity 0s 0.35s;
  width: 100%;
  height: auto;
}

.expandButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.expandButton {
  background-color: black;
  color: white;
  padding: 10px 12px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 500px;
  border: 1px solid transparent;
  text-decoration: none;
  width: fit-content;

  &:hover, &:active {
    cursor: pointer;
    background-color: white;
    color: black;
    border: 1px dashed black;
  }
}

.Label {
  width: fit-content;
  font-size: 16px;
  border-radius: 100px;
  padding: 14px;
}
.Title {
  font-family: NHGDP, sans-serif;
  @include responsiveMediumHeading();

  @media (max-width: 1350px) {
    font-size: 42px;
  }
  @media (max-width: 1230px) {
    font-size: 40px;
  }
  @media (max-width: 930px) {
    font-size: 38px;
  }
  @media (max-width: 900px) {
    font-size: 36px;
  }
  @media (max-width: 700px) {
    font-size: 32px;
  }
  @media (max-width: 500px) {
    font-size: 28px;
  }
  @media (max-width: 400px) {
    font-size: 24px;
  }
}
.titleLeft {
  align-self: flex-start;
}

.featureIllustrationVideoContainer {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  width:100%;
  // below 650px make the video fixed size
  @media (max-width: 650px){
    justify-content: flex-start;
    //max-width: 250px;
  }
}

.illustrationWrapper {
  position: relative;
  // below 650px make the video fixed size
  @media (max-width: 650px){
    width: 100%;
    //min-height: 300px;
    //min-width: 300px;
  }
}

.illustrationVideoCSS {
  position: absolute;
  z-index: 1;
  min-height: 300px;
  min-width: 300px;
  background-color: transparent;
  background-position: center center;
  background-size: 140%;
  background-repeat: no-repeat;
}

.featureIllustrationVideo {
  position: relative;
  z-index: 0;
  pointer-events: none;
  margin: -220px;
  transform: scale(0.7);
  // below 650 makethe video smaller
  @media (max-width: 650px){
    margin: -250px;
    transform: scale(0.5);
  }
}