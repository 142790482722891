// Variables for typography styles font sizes

// Extra large heading
$headingExtraLarge: 120px;

// Large heading
$headingLarge: 64px;

// Medium heading
$headingMedium: 48px;

// Small heading
$headingSmall: 40px;

// mobile large heading
$headingLargeMobile: 32px;

// mobile medium heading
$headingMediumMobile: 32px;

// mobile extra large heading
$headingExtraLargeMobile: 48px;

// Mixin for using mobile font sizes under 650px

// Extra large heading
@mixin responsiveExtraLargeHeading {
  font-size: $headingExtraLarge;
  font-weight: normal;
  font-family: NHGDP, sans-serif;

  margin: 0;
  margin-block: 0;

  @media (max-width: 650px){
    font-size: $headingExtraLargeMobile;
  }
}

// Extra large heading
@mixin responsiveLargeHeadingAbout {
  font-size: $headingLarge;
  font-weight: normal;
  font-family: NHGDP, sans-serif;

  margin: 0;
  margin-block: 0;

  @media (max-width: 1000px){
    font-size: $headingExtraLargeMobile;
  }
  @media (max-width: 650px){
    font-size: $headingMediumMobile;
  }
}

// Large heading
@mixin responsiveLargeHeading {
  font-size: $headingLarge;
  font-weight: normal;
  font-family: NHGDP, sans-serif;

  margin: 0;
  margin-block: 0;

  @media (max-width: 650px){
    font-size: $headingExtraLargeMobile;
  }
}

// Medium heading
@mixin responsiveMediumHeading {
  font-size: $headingMedium;
  font-family: NHGDP, sans-serif;
  @media (max-width: 650px){
    font-size: $headingMediumMobile;
  }
}

// Small heading
@mixin responsiveSmallHeading {
  font-family: NHGDP, sans-serif;
  font-size: $headingSmall;
}