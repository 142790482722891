.contactUsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;

  @media (max-width: 650px){
    flex-direction: column;
  }
}

.contactUsFormContainer {
  position: relative;
  width: 100%;
}

.contactUsForm h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 24px;
  color: black;
}

.formGroup {
  margin-bottom: 16px;
}

.formGroup label {
  display: block;
  margin-bottom: 4px;
  color: #303030;
  position: relative;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.formGroup textarea {
  resize: vertical;
  min-height: 64px;
}

.submitGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;

  button {
    background-color: black;
    color: white;
    padding: 10px 12px;
    border-radius: 500px;
    border: 1px solid transparent;
    text-decoration: none;
    cursor: pointer;
  }

  button:hover {
    background-color: white;
    color: black;
    border: 1px dashed black;
  }

  button.disabledBtn {
    background-color: #ddd;
    color: #aaa;
    border: 1px solid transparent;
    cursor:unset;
  }

  .successMessage {
    color: green;
  }

  .errorMessage {
    color: red;
  }
}

.contactUsEmailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.dontLikeFormsText {
  font-weight: 500;
  color: black;
  opacity: 50%;
  font-family: NHGDP, sans-serif;
  font-size: 24px;
}

.sendEmailInsteadText {
  font-weight: 500;
  color: black;
  font-family: NHGDP, sans-serif;
  font-size: 20px;
}

.email {
  margin-top: 8px;
  text-align: center;
}