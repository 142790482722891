.grayText {
  color: rgba(0, 0, 0, 0.5);
}
.grayTextOnNonWhite {
  color: rgb(255, 255, 255, 0.5);
}
.blackText {
  color: #000000;
}
.hideOnMobile {
  // below 600px hide this
  @media  (max-width: 650px) {
    display: none;
  }
}
.hideOnTablet {
  // below 1000px hide this
  @media  (max-width: 1000px) {
    display: none;
  }
}