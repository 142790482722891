@import "styles/typography.scss";

.aboutSectionHeader {
  @include responsiveLargeHeadingAbout();
  padding-bottom: 128px;
  // below 650px reduce bottom padding to 64px
  @media (max-width: 650px){
    padding-bottom: 64px;
  }

  h2 {
    @include responsiveLargeHeadingAbout();
  }
}

.aboutElementWrapper {
  width: 100%;
  border-top: 1px dashed black;
}

/*
.aboutElementWrapper:nth-child(odd) .aboutElementSideBySideContainer {
  flex-direction: row-reverse;
  // below 900 do not reverse
  @media (max-width: 1000px){
    flex-direction: column;
  }
}
*/

.aboutElementSideBySideContainer {
  padding-bottom: 128px;
  padding-top: 32px;
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px){
    flex-direction: column;
  }
}

.aboutElementTeamContainer {
  padding-bottom: 32px;
  padding-top: 64px;
  display: flex;
  gap: 64px;
  flex-direction: column;
  // below 650 reduce gap to 64
  @media (max-width: 650px){
    gap: 64px;
  }
}

.TitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  //max-width: 550px;
  flex: 1;
  align-self: flex-start;
}
.Title {
  font-family: NHGDP, sans-serif;
  @include responsiveMediumHeading();
}
.titleLeft {
  align-self: flex-start;
}

.aboutSectionHeaderSubText {
  padding-top: 16px;
  font-size: 18px;
  font-family: NHGDP, sans-serif;
  display: flex;
  gap: 8px;
  flex-direction: row;
  // below 600 make the direction column
  @media (max-width: 600px){
    flex-direction: column;
  }
}

.aboutSectionHeaderSubTextTitle {
  font-family: "Fragment Mono", monospace;
}

.aboutSectionHeaderSubTextTitle::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
  margin-right: 8px;
  transform: translateY(-2px);
}

.aboutElementContentWrapper {
  flex: 1;
}

.aboutElementContent {
  font-family: NeueHaasGroteskTextPro, sans-serif;
  font-size: 16px;
  line-height: 140%;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  row-gap: 64px;
  margin: 0 -32px;
  @media (max-width: 1000px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 650px){
    grid-template-columns: repeat(1, 1fr);
    row-gap: 32px;
  }
}

.teamItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 32px;
  align-items: flex-start;
  border-left: 1px dashed black;
  // below 1000px do not show border
  @media (max-width: 650px){
    border-right: none;
    border-top: 1px dashed black;
    padding: 32px 0px 0;
    margin:0 32px;
    border-left: 1px dashed transparent;
    font-size: 14px;
  }
}

.teamItem:nth-child(3n + 1) {
  border-left: 1px dashed transparent;
  @media (max-width: 1000px){
    border-left: 1px dashed black;
  }
  @media (max-width: 650px){
    border-left: 1px dashed transparent;
  }
}

//.teamItem:nth-child(3n + 1) {
//  padding-left: 0;
//  @media (max-width: 1000px){
//    padding-left: 32px;
//  }
//}

.teamItem:nth-child(2n + 1) {
  @media (max-width: 1000px){
    border-left: 1px dashed transparent;
  }
}

//.teamItem:nth-child(3n) {
//  border-right: none;
//}

.teamItemName, .joinUsText {
  font-family: NHGDP, sans-serif;
  font-size: 20px;
  // below 650 change font size to 14
  @media (max-width: 650px){
    font-size: 14px;
  }
}

.teamItemRole:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
  margin-right: 8px;
  transform: translateY(-2px);
}

.joinUsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px dashed black;
  padding: 32px;
  border-radius: 32px;
}

.bitcoinerJobsButton {
  display:flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  background-color: #222222; // bitcoiner jobs img color
  color: white;
  padding: 12px 18px;
  border-radius: 500px;
  border: 1px solid transparent;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;

  img {
    max-height: 24px;
    max-width: 24px;
    margin: -8px 0;
    border-radius: 6px;
    transform: scale(1.4); // cut excess borders in the image
  }

  &:hover {
    background-color: white;
    color: black;
    border: 1px dashed black;
  }
}

.illustrationWrapper {
  position: relative;
  // below 650px make the video fixed size
  @media (max-width: 650px){
    min-height: 300px;
    min-width: 300px;
  }
}

.illustrationVideoCSS {
  position: absolute;
  z-index: 1;
  min-height: 300px;
  min-width: 300px;
  background-color: transparent;
  background-position: center center;
  background-size: 140%;
  background-repeat: no-repeat;
}

.flowChartIllustrationVideoContainer {
  overflow: hidden;
  z-index: -1;
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  // below 650px make the video fixed size
  @media (max-width: 650px){
    display: none;
  }
  // hide the video on safari iOS
  @supports (-webkit-overflow-scrolling: touch) {
    display: none;
  }
}

.flowChartIllustrationVideo {
  pointer-events: none;
  margin: -310px;
  transform: scale(0.45);
}