@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono&display=swap');
@import "styles/typography.scss";

.footerWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 90px;
}

// footerContainer has the white background
.footerContainer {
  background-color: white;
  width: 100%;
  max-width: 1600px;
  margin: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  @media  (max-width: 650px) {
    margin: 0;
  }
}

.testimonialsContainer {
  padding: 0;
  padding-top: 4px;
  width: 100%;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.messageContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 240px);
  padding: 100px 0;
  margin: 0 32px;
  border: 0 dashed black;
  border-bottom-width: 1px;
  gap: 32px;
  // below 900px reduce margin to 32px
  @media  (max-width: 1000px) {
    padding: 80px 0;
    width: calc(100% - 80px);
    margin: 0 32px;
  }
  // below 650px reduce padding to 80px
  @media  (max-width: 650px) {
    padding: 64px 0;
    width: calc(100% - 32px);
  }
}
.message {
  @include responsiveLargeHeading();
}
.actionButtons {
  display: flex;
  gap: 8px;
}

.linkContainer {
  width: 100%;
}
.linkWrapper {
  display: flex;
  justify-items: flex-end;
  margin: 16px 120px;
  // below 900px reduce margin to 32px
  @media  (max-width: 1000px) {
    margin: 32px 32px;
  }
  // below 650px reduce margin to 32px
  @media  (max-width: 650px) {
    margin: 16px 0;
  }
}
.disclaimer {
  width: 100%;
}
.disclaimerWrapper {
  //border-top: 1px dashed black;
  //padding: 32px 16px;
  display: flex;
  font-family: NeueHaasGroteskTextPro, sans-serif;
  line-height: 140%;
  justify-items: flex-end;
  margin: 0 120px 32px;
  color: rgba(0, 0, 0, 0.5);
  // below 900px reduce margin to 32px
  @media  (max-width: 1000px) {
    margin: 32px 32px;
  }
  // below 650px reduce margin to 32px
  @media  (max-width: 650px) {
    margin: 16px 16px;
  }
}

.footerLinks {
  font-family: "Fragment Mono", monospace;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding-right: 8px;
  @media  (max-width: 900px) {
    flex-direction: column;
  }
}
  .footerLinkItem, a.footerLinkItem, a.footerLinkItem:visited {
    color: black;
    text-decoration: none;
    padding: 12px 16px;
    border-radius: 500px;
  }
  a.footerLinkItem:hover, a.footerLinkItem:active {
    cursor: pointer;
    color: white;
    background-color: black;
  }
  a.actionButton, a.actionButton:visited {
    background-color: black;
    color: white;
    padding: 10px 12px;
    border-radius: 500px;
    border: 1px solid transparent;
    text-decoration: none;
  }
  a.actionButton:hover, a.actionButton:active {
    background-color: white;
    color: black;
    border: 1px dashed black;
  }
  .actionButton.white {
    background-color: transparent;
    color: black;
    border: 1px solid transparent;
  }